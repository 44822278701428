// src/App.js
import React, { useState } from 'react';
import './App.css';

const App = () => {
  const [code, setCode] = useState('');
  const [isCodeCorrect, setIsCodeCorrect] = useState(false);
  const [downloadMessage, setDownloadMessage] = useState('');

  // Function to verify the entered code
  const verifyCode = () => {
    // Get the current date in mmdd format
    const currentDate = new Date();
    const mmdd = `${currentDate.getMonth() + 1}${currentDate.getDate()}`;

    if (code === mmdd) {
      setIsCodeCorrect(true);
    } else {
      alert('Incorrect code. Please try again.');
      setIsCodeCorrect(false);
    }
  };

  // Function to handle downloads
  const handleDownload = (fileName, fileUrl) => {
    // Show the "Please wait... downloading" message
    setDownloadMessage("Please wait... downloading");

    // Create and trigger the anchor for file download
    const anchor = document.createElement("a");
    anchor.href = fileUrl;
    anchor.download = fileName;
    anchor.click();

    // Simulate a download completion delay (e.g., 5 seconds)
    setTimeout(() => {
      setDownloadMessage(
        'Downloading will be finishing soon, please go to files and then downloads and once fully downloaded click on it to install it.'
      );
    }, 10000); // Adjust the delay time (5000ms = 5 seconds)
  };

  return (
    <div className="app-container">
      <h1>Welcome to the Download Portal</h1>

      {/* Commented out the code input box and associated text/button */}
      {/* <div className="input-container">
        <label htmlFor="code-input">Enter your code:</label>
        <input
          id="code-input"
          type="text"
          value={code}
          onChange={(e) => setCode(e.target.value)}
          placeholder="Enter your code here"
        />
        <button onClick={verifyCode}>Submit</button>
      </div> */}

      <div className="download-buttons">
        <button
          onClick={() =>
            handleDownload('psp.apk', 'https://pharmaceinstall.co.uk/psp.apk')
          }
        >
          Download staff portal
        </button>
        <button
          onClick={() =>
            handleDownload('m1.apk', 'https://pharmaceinstall.co.uk/m1.apk')
          }
        >
          Download scan to shelf
        </button>
        <button
          onClick={() =>
            handleDownload('m2.apk', 'https://pharmaceinstall.co.uk/m2.apk')
          }
        >
          Download delivery app
        </button>
        {/* New "TS only" button */}
        <button
          onClick={() =>
            handleDownload('TS.apk', 'https://pharmaceinstall.co.uk/TS.apk')
          }
        >
          TS only
        </button>
      </div>

      {downloadMessage && (
        <div className="dialogue-box">
          <p>{downloadMessage}</p>
          <button onClick={() => setDownloadMessage('')}>OK</button>
        </div>
      )}
    </div>
  );
};

export default App;
